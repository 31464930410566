<template>
  <div>
    <div class="flex next-btn">
      <div>
        <div style="font-size: 36px;">{{ $t('param.a5') }}</div>
      </div>
      <div>
        <el-button v-print="'printObj'" type="primary" @click="printClick"
          v-loading.fullscreen.lock="fullscreenLoading">{{ $t('common.a12') }}
        </el-button>
        <!--权限控制-->
        <el-button v-if="quanxian == 'Config' || quanxian == 'Admin'" v-show="edit == 'true'" size="large"
          type="primary" @click="save(true)">{{ $t('common.a4') }}
        </el-button>
      </div>
    </div>
    <!--打印区域-->
    <div id="print_html" ref="print_html">
      <div class="shortcutkeys">
        <el-table :data="tableData" class="table mar30" border style="width: 100%;">
          <el-table-column prop="name" width="200" :label="$t('param.a30')" align="center">
            <template slot-scope="scope">
              <i v-if="scope.$index>3 && scope.$index<8" :class="scope.row.name" style="font-size: 25px;"></i>
              <span v-else style="font-size: 20px;">{{ scope.row.name }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" :label="$t('param.a31')">
            <template slot-scope="scope">
              <div v-if="digitKeys.includes(scope.row.param)">
                <el-form label-position="left" label-width="0" :rules="rules" :model="scope.row.form">
                  <el-row>
                    <el-col :span="12">
                      <el-form-item prop="numberType">
                        <el-radio-group v-model="scope.row.form.numberType">
                          <el-radio label="MSISDN">{{ $t('param.a27') }}</el-radio>
                          <el-radio label="VGCS">{{ $t('param.a28') }}</el-radio>
                          <el-radio label="VBS">{{ $t('param.vbs') }}</el-radio>
                        </el-radio-group>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item prop="number">
                        <el-input :maxlength="20" v-model="scope.row.form.number" @input="inputCheck" class="input-set"
                          size="small"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
              <div v-else>
                <el-select v-model="scope.row.value" @change="choosevalue(scope.row, true)" placeholder=" ">
                  <el-option :label="$t('param.a32')" value=""></el-option>
                  <el-option v-for="item in options" :key="item.value" :label="$t('shortcutMenu.' + item.value)"
                    :value="item.value">
                  </el-option>
                </el-select>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <!-- button -->
    <div class="flex mar30 next-btn" v-show="edit != 'true'">
      <el-button size="large" type="primary" @click="back">{{ $t('common.a5') }}</el-button>
      <el-button size="large" type="primary" @click="save(false)">{{ $t('common.a6') }}</el-button>
    </div>
  </div>
</template>

<script>
import List from '@/assets/data/shortcutmenus_sncf.json'
const MMI_GSMR_CALL = /(\*(1[78])#)?(\+?[0-9]*)/;
/*           1     2      3
             1 = call type with *
             2 = call type
             3 = dialing number
*/
const NUMBER_REGEX = /^\d+$/;

export default {
  data() {
    return {
      options: [{
        name: 'Main menu',
        value: 'main_menu'
      }, {
        name: 'Railway calls',
        value: 'gsmr_feature_railway_calls'
      }, {
        name: 'Quick dial',
        value: 'railway_quick_dial'
      }, {
        name: 'FN(TN) dial',
        value: 'fid_tn_dial'
      }, {
        name: 'FN(EN) dial',
        value: 'fid_en_dial'
      }, {
        name: 'FN(CN) dial',
        value: 'fid_cn_dial'
      }, {
        name: 'VGCS/VBS',
        value: 'gsmr_feature_vgcs_vbs'
      }, {
        name: 'Initiate VGCS',
        value: 'initiate_vgcs'
      }, {
        name: 'Network mode',
        value: 'network_selection_mode_setting_label'
      }, {
        name: 'Phonebook',
        value: 'contacts'
      }, {
        name: 'unregistration',
        value: 'unregistration'
      }],
      id: '',//上个页面menu的id
      shortcutkey: '',//当前键位设置表单的id
      edit: false,
      check: false,//用来判断在编辑状态下是否有未保存的更改
      tableData: [
        {name: 'I', value: '', param: 'one'},
        {name: 'II', value: '', param: 'two'},
        {name: 'III', value: '', param: 'three'},
        {name: 'Ⅳ', value: '', param: 'four'},
        {name: 'el-icon-arrow-up', value: 'main_menu', param: 's'},
        {name: 'el-icon-arrow-down', value: 'contacts', param: 'x'},
        {name: 'el-icon-arrow-left', value: '', param: 'z'},
        {name: 'el-icon-arrow-right', value: '', param: 'y'},
        {name: '1', value: '', param: 'key1', form: {numberType: 'MSISDN', number: ''}},
        {name: '2', value: '', param: 'key2', form: {numberType: 'MSISDN', number: ''}},
        {name: '3', value: '', param: 'key3', form: {numberType: 'MSISDN', number: ''}},
        {name: '4', value: '', param: 'key4', form: {numberType: 'MSISDN', number: ''}},
        {name: '5', value: '', param: 'key5', form: {numberType: 'MSISDN', number: ''}},
        {name: '6', value: '', param: 'key6', form: {numberType: 'MSISDN', number: ''}},
        {name: '7', value: '', param: 'key7', form: {numberType: 'MSISDN', number: ''}},
        {name: '8', value: '', param: 'key8', form: {numberType: 'MSISDN', number: ''}},
        {name: '9', value: '', param: 'key9', form: {numberType: 'MSISDN', number: ''}}
      ],
      rules: {
        number: [
          {
            pattern: NUMBER_REGEX, message: this.$t('common.number_only'), trigger: 'blur'
          }
          // {
          //   validator: (rule, value, callback) => {
          //     if (value.includes('299')) {
          //       callback(new Error('电话号码不允许包含299'));
          //     } else {
          //       callback();
          //     }
          //   },
          //   trigger: 'blur'
          // }
        ]
      },
      quanxian: '',
      printObj: {
        id: 'print_html', //打印标签的id
      },
      fullscreenLoading: false,
      digitKeys: ['key1', 'key2', 'key3', 'key4', 'key5', 'key6', 'key7', 'key8', 'key9']
    }
  },
  created() {
    this.edit = sessionStorage.getItem('edit');

    //为4和5设置默认值
    this.choosevalue(this.tableData[4])
    this.choosevalue(this.tableData[5])
    if (this.edit == 'false') {// 如果是新增，用户填写数据没有点击下一步保存的情况下做一个缓存的回显
      const cache = sessionStorage.getItem('shortcutkeysCache')
      if (cache && cache != 'null') {
        this.tableData = JSON.parse(cache).map(v => {
          if (!this.options.find(it => it.value == v.value)) {
            v.value = '';
            delete v.values;
          }
          return v;
        })
      }
      sessionStorage.removeItem('shortcutkeysCache')
    }

    let data = sessionStorage.getItem('file')
    if (data) {
      this.pid = JSON.parse(data).id;
    }

    this.id = this.$route.query.id;
    this.getList();

    let user = sessionStorage.getItem('user');
    if (user) this.quanxian = JSON.parse(user).quanxian;
  },
  mounted() {
    this.getOptions();
  },
  beforeRouteLeave(to, from, next) {
    if (this.edit == 'true' && this.check) {// 如果是编辑的时候没有保存，加一个提示
      this.$confirm(this.$t('message.a13'), this.$t('message.a10'), {
        confirmButtonText: this.$t('common.a4'),
        cancelButtonText: this.$t('cp.a5'),
        type: 'warning'
      }).then(() => {
        if (this.save(true) === false) {
          next(false)
        } else {
          next();
        }
      }).catch(() => {
        next()
      });
    } else if (this.edit == 'true' || (
        to.path == '/Contacts' ||
        to.path == '/BasicParameters' ||
        to.path == '/Menus' ||
        to.path == '/Shortcutkeys' ||
        to.path == '/ForbiddenNumbers'
    )) {
      if (this.edit == 'false') {
        sessionStorage.setItem('shortcutkeysCache', JSON.stringify(this.tableData))
      }
      return next();
    } else {
      this.$confirm(this.$t('message.a11'), this.$t('message.a10'), {
        confirmButtonText: this.$t('cp.a4'),
        cancelButtonText: this.$t('cp.a5'),
        type: 'warning'
      }).then(() => {
        if (this.pid) {
          this.postRequest('index/del_phone_profile', {ids: this.pid}, () => {
            this.$message({
              type: 'success',
              message: this.$t('message.a6')
            });
          })
        }
        next();
      }).catch(() => {
        next(false)
      });
    }
  },
  methods: {
    printClick() {
      this.fullscreenLoading = true;
      this.html2canvas(this.$refs.print_html, {
        backgroundColor: null,
        useCORS: true,
        windowHeight: document.body.scrollHeight
      }).then((canvas) => {
        const url = canvas.toDataURL()
        this.printJS({
          printable: url,
          type: 'image',
          documentTitle: ''
        })
        this.fullscreenLoading = false;
      });
    },
    getList() {
      this.postRequest('index/get_shortcutkeys', {
        uid: sessionStorage.getItem('id'),
        pid: this.pid
      }, res => {
        this.shortcutkey = res.data.id;
        this.tableData.forEach(v => {
          if (this.digitKeys.includes(v.param)) {
            v.form.number = this.getMmiCodeNumber(res.data[v.param]);
            v.form.numberType = this.getNumberType(this.getPrefixMMICodeByNumber(res.data[v.param]));
          } else {
            v.value = res.data[v.param];
            v.values = List.find(val => val.name_res_id == v.value);
          }
        })
      })
    },
    getOptions() {
      this.postRequest('index/xiala', {
        uid: sessionStorage.getItem('id'),
        pid: this.pid
      }, res => {
        if (!res.data || res.data.length == 0) {
          return;
        } else {
          let arr = Object.keys(res.data);
          if (arr.indexOf('gsmr_vc') > -1) {
            this.options = this.options.concat([
              {
                name: 'Virtual Channel',
                value: 'virtual_channel'
              },
              {
                name: '1200',
                value: 'vc_1200'
              },
              {
                name: '1300',
                value: 'vc_1300'
              },
              {
                name: '1400',
                value: 'vc_1400'
              }
            ])
          }
          if (arr.indexOf('gsmr_init_rec') > -1) {
            this.options = this.options.concat([
              {
                name: 'Initiate Railway Emerg.Call',
                value: 'emerg_call'
              }
            ]);
          }
          if (arr.indexOf('gsmr_mng_fn') > -1) {
            this.options = this.options.concat([
              {
                name: 'Manage FN',
                value: 'gsmr_feature_fn_management'
              },
              {
                name: 'FN(TN) Reg.',
                value: 'tn_reg'
              },
            ]);
          }
          if (arr.indexOf('settings_cell_mnt') > -1) {
            this.options = this.options.concat([
              {
                name: 'Cell parameters',
                value: 'location_cell_monitoring'
              }
            ]);
          }
        }
      })
    },
    inputCheck() {
      this.tableData.forEach(v => {
        // if (this.digitKeys.includes(v.param)) {
        //   console.log(`v.form.number: ${v.form.number}`);
        //   //v.form.number = v.form.number.replace(/[^0-9*+#]*/g, '').substr(0, 19)
        // }
      })
    },
    choosevalue(item, control) {
      item.values = List.find(val => val.name_res_id == item.value);
      control === true ? this.check = control : '';
    },

    back() {
      this.$root.status = 'Menus'
      this.$router.go(-1)
    },
    save(isSave) {
      let params = {
        pid: this.pid,
        uid: sessionStorage.getItem('id'),
      }
      let needExit = false;
      let have = this.tableData.find(v => v.value == 'main_menu')
      if (!have) {
        this.$message({
          type: 'warning',
          message: this.$t('message.a7')
        })
        return false;
      }

      if (this.shortcutkey) params.id = this.shortcutkey;

      this.tableData.forEach(v => {
        if (this.digitKeys.includes(v.param)) {
          if (v.form.number !== '' && !NUMBER_REGEX.test(v.form.number)) {
            this.$message({
              type: 'warning',
              message: `Key ${v.name} ${this.$t('common.number_only')}`
            });
            needExit = true;
            return;
          }
          params[v.param] = this.getPrefixMMICode(v.form.numberType) + this.getMmiCodeNumber(v.form.number);
        } else {
          params[v.param] = v.values ? JSON.stringify(v.values) : '';
        }
      })
      if (needExit) {
        return false;
      }
      this.postRequest('index/set_shortcutkeys', params, () => {
        if (isSave && this.pid) {// 如果点击保存按钮
          // this.$message({
          //   type: 'success',
          //   message: this.$t('message.a5')
          // })
          this.uploadXML();
        } else {//点击下一部按钮
          this.$root.status = 'ForbiddenNumbers';
          this.$router.push('/ForbiddenNumbers');
        }
      }, true)
    },
    uploadXML() {
      this.postRequest('index/create_xml', {
        id: this.pid,
        uid: sessionStorage.getItem('id')
      }, () => {
        this.$message({
          type: 'success',
          message: this.$t('message.a5')
        });
        this.check = false;
      })
    },
    getPrefixMMICode(numberType) {
      switch (numberType) {
        case 'MSISDN':
          return '';
        case 'VGCS':
          return '*17#';
        case 'VBS':
          return '*18#';
        default:
          return ''; // or some other default value
      }
      //Alternatively
      // const lookup = {
      //   MSISDN: '',
      //   VGCS: '*17#',
      //   VBS: '*18#'
      // };
      // return lookup[radio]
    },
    getNumberType(PrefixMMICode) {
      switch (PrefixMMICode) {
        case '':
          return 'MSISDN';
        case '*17#':
          return 'VGCS';
        case '*18#':
          return 'VBS';
        default:
          return ''; // or some other default value
      }
      //Alternatively
      // const lookup = {
      //   '': 'MSISDN',
      //   '*17#': 'VGCS',
      //   '*18#': 'VBS'
      // };
      // return lookup[value];
    },
    getPrefixMMICodeByNumber(number) {
      if (number) {
        const match = number.match(MMI_GSMR_CALL);
        if (match && typeof match[1] !== 'undefined') {
          return match[1];
        }
      }
      return '';
      // if (number.startsWith('*17#')) {
      //   return '*17#';
      // } else if (number.startsWith('*18#')) {
      //   return '*18#';
      // } else {
      //   return '';
      // }
      //Alternatively
      // const regex = /^(\*17#|\*18#)/;
      // const match = str.match(regex);
      // return match ? match[1] : '';
    },
    getMmiCodeNumber(number){
      if (number) {
        const match = number.match(MMI_GSMR_CALL);
        if (match) {
          return match[3];
        }
      }
      return number;
    }
  }
}
</script>

<style>
.input-set .el-input__inner {
  border: 1px solid #C7C5B8;
  border-radius: 10px;
  text-align: center;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  background-color: #f2f2f2;
}
.shortcutkeys .el-select {
  width: 100%;
}

.el-select-dropdown__item {
  text-align: center !important;;
}

.shortcutkeys .el-input__inner {
  text-align: center;
  border: none !important;
}

.shortcutkeys td.el-table__cell {
  padding: 0 !important;
}
</style>
<style scoped>
.search {
  width: 100%;
  height: 46px;
  border: 3px solid #5D80F5;
  border-radius: 1px;
  font-size: 18px;
  text-indent: 20px;
  box-sizing: border-box;

}

.search:focus-visible {
  outline: none;
}

.search-btn {
  width: 11%;
  height: 46px;
  line-height: 46px;
  text-align: center;
  font-size: 22px;
  cursor: pointer;
  color: #C3D0FB;
  background: #5D80F5;
}

.flex {
  display: flex;
  justify-content: space-between;
}

.next-btn {
  margin-bottom: 30px;
}

.mar30 {
  margin-top: 30px;
}

</style>
